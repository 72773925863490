/* eslint-disable quotes */
class MetaInfo {
  constructor (title, description, keywords, image, url) {
    this.title = title
    this.description = description
    this.keywords = keywords
    this.image = image
    this.url = url
  }
}
const cabaretMeta = new MetaInfo('Cabaret Festif! - 14e édition',
  'Le Cabaret Festif!, volet concours du festival Le Festif! est né en 2011, suite à la deuxième édition du festival.',
  'cabaret, festif, baie-saint-paul, relève, musique, spectacle',
  'https://images.prismic.io/le-festif-ca/Zygkk68jQArT0JW3_og_cabaret.png',
  'https://lefestif.ca/cabaret')

class CabaretArtist {
  constructor (name,
    slug,
    date,
    website,
    facebook,
    instagram,
    spotify,
    apple,
    bandcamp,
    bio,
    youtubeVideoId) {
    this.name = name
    this.slug = slug
    this.date = date
    this.website = website
    this.facebook = facebook
    this.instagram = instagram
    this.spotify = spotify
    this.apple = apple
    this.bandcamp = bandcamp
    this.bio = bio
    this.youtubeVideoId = youtubeVideoId

    this.getImageLocalPath = `cabaret/artists/${this.slug}.jpg`
  }
}

const janvier27 = '27 janvier 2024'
const fevrier17 = '17 février 2024'
const mars9 = '9 mars 2024'
const allCabaretCandidats =
[
  new CabaretArtist(
    'oui merci',
    'ouimerci',
    janvier27,
    'https://ouimerciband.com/',
    'https://www.facebook.com/ouimercimtl',
    'https://www.instagram.com/ouimercibeaucoup/',
    'https://open.spotify.com/intl-fr/artist/6XUr1bWIBVkLmXHySucgyr?si=WsYo8o1uR-ubi0SzmjxK8w',
    'https://music.apple.com/us/artist/oui-merci/1524873756',
    'https://ouimerci.bandcamp.com/album/ok-bebye',
    "oui merci commence avec l’amitié, car c’est en 2019, alors que plusieurs membres du band habitent ensemble, que l’idée fleurit d’elle-même (peut-être avec l’aide d’une bière, il faut l’avouer). Nait tranquillement de cette union un indie-rock-poli marqué par des arrangements qui oscillent entre précision chirurgicale et accidents volontaires. Une musique comme un petit ouragan d’émotions entremêlées les unes dans les autres, ancrée dans son époque mais toujours sincère, puisque mue par le désir d’être ensemble.",
    'M4vQ_PgOveo'
  ),
  new CabaretArtist(
    'Oliver Forest',
    'oliverforest',
    janvier27,
    'https://oliverforest.com/',
    'https://www.facebook.com/oliverforestofficial',
    'https://www.instagram.com/oliverforestband/',
    'https://open.spotify.com/intl-fr/artist/0suucqqvdSvSNRyfvzEBe7?si=8JevvDYxT_2cOxm2dIS4NA',
    'https://music.apple.com/us/artist/oliver-forest/614418569',
    'https://flarak.bandcamp.com/album/moments',
    "Oliver Forest fusionne habilement folk, pop et rock. Le son du groupe est un témoignage de l'art et de la créativité du groupe, mêlant des mélodies puissantes et une instrumentation riche pour créer une expérience d'écoute unique. L'esprit captivant et le talent indéniable d'Oliver Forest s’unissent en un spectacle incontournable pour tous les amateurs de musique. Que vous soyez un fan inconditionnel ou un néophyte de leur son, vous serez certainement emporté par la magie de ce groupe hors du commun.",
    'JY1sLuKQGoM'
  ),
  new CabaretArtist(
    'Anaïs Constantin',
    'anaisconstantin',
    janvier27,
    'https://lecanalauditif.ca/artistes/anais-constantin/',
    'https://www.facebook.com/anaisconstantinmusique',
    'https://www.instagram.com/anaisconstantin/',
    'https://open.spotify.com/intl-fr/artist/16rv4gKc1NU7DDlS9jYjKQ?si=kqlKlG1GSYK8KmAF7LYBOg',
    'https://music.apple.com/us/artist/ana%C3%AFs-constantin/1318432856',
    'https://anaisconstantin.bandcamp.com/album/isle-aux-coudres',
    "Anaïs Constantin est un petit kiosque de poésie milléniale inattendue qui joue de la guitare ténor baryton comme une fille complexe et décomplexée. Attachantes et accessibles, ses chansons post post postmodernes ont quelque chose qui pourrait rappeler les mélodies accrocheuses de Dumas, mais aussi les carnets brûlants de vérité et l’engagement profond de Maude Veilleux. Le ton navigue entre l’intime et le sarcastique, le quirky et le doux amer. Les thèmes quotidiens, campés d ans un ordinaire tendre et parfois légèrement décalé, sont livrés avec justesse, sans artifice. Au final, ça donne un genre d’indie folk queb DIY dépouillé et sensible, où il est question de nintendo et de lilas bruns, entre autres.",
    '3D8438bA2mI'
  ),
  new CabaretArtist(
    'Sloan Lucas',
    'sloanlucas',
    janvier27,
    'https://francouvertes.com/artistes/sloan-lucas/',
    'https://www.facebook.com/bysloanlucas',
    'https://www.instagram.com/bysloanlucas/',
    'https://open.spotify.com/intl-fr/artist/12weXKtxUbWsbb1AqlZ3lH?si=rgsABtF4RX6HEz08yNZyTA',
    'https://music.apple.com/us/artist/sloan-lucas/1478474623',
    'https://sloanlucas.bandcamp.com/',
    "Issue d’un parcours non linéaire, tissé entre autres de pratiques artistiques et militantes, la rappeuse montréalaise Sloan Lucas n’a pas besoin qu’on lui explique que le monde va très, très mal, et n’a pas non plus le projet de proposer des solutions : elle est pessimiste, voire cynique par moments. Capable de déployer une grande énergie sur scène, elle se permet aussi de doser : elle passe sans préavis de l’humour au sérieux, du doute à l’assurance. Variant aisément son flow, elle pose sa voix ingrate sur des productions dansantes, mélancoliques ou agressives, offrant au public un voyage à travers ses sautes d’humeur.",
    '1HCmB1lC4gg'
  ),
  new CabaretArtist(
    'Corail',
    'corail',
    fevrier17,
    'https://lecanalauditif.ca/artistes/corail-groupe/',
    'https://www.facebook.com/corailbandmtl',
    'https://www.instagram.com/corail_inthesky/',
    'https://open.spotify.com/intl-fr/artist/6gBH7tdzTkIiWtVw8JCwzr?si=2b-LcxgXTlajrs_2-izzyQ',
    'https://music.apple.com/us/artist/corail/1487673574',
    'https://corailband.bandcamp.com/',
    "CORAIL est un duo québécois composé de Julien Comptour (Mort Rose) et Philippe Noël. La formation montréalaise avait titillé l’oreille en sortant deux singles planants fin 2019 et début 2020 — Lampadaire et Très Doucement. Le premier EP de CORAIL arrive sur les tablettes le 29 janvier 2021. La formation a lancé son premier album, Maison, en septembre 2023.",
    'rw8jgSaL6jM'
  ),
  new CabaretArtist(
    'Halde',
    'halde',
    fevrier17,
    'https://www.haldesabrina.com/',
    'https://www.facebook.com/haldesabrina',
    'https://www.instagram.com/haldesab/',
    'https://open.spotify.com/intl-fr/artist/1yUTHUNi6qITdPNtwATgtp?si=Lel6t6TgR_ycx1SzTmORDQ',
    'https://music.apple.com/us/artist/halde/1547370380',
    'https://halde.bandcamp.com/album/the-flow',
    "Halde est chanteuse et cofondatrice de l'ancien groupe montréalais Groenland, qui s’est démarqué avec presque 3 millions de vues pour sa musique sur Youtube, la vente de plus de 33 000 albums et des salles combles au Canada et en Europe. Après le succès du band, Sabrina prend un temps pour se ressaisir et vivre une transformation déclenchée par les deuils dans sa vie. Elle s'installe dans une petite maison à Chertsey pour guérir, créer et se retrouver. Elle revient avec son premier album solo qui sortira en 2022.",
    'se0L_yetzq4'
  ),
  new CabaretArtist(
    'Bermuda',
    'bermuda',
    fevrier17,
    'https://bermudamusique.com/',
    'https://www.facebook.com/Bermudamusique',
    'https://www.instagram.com/bermudamusique/',
    'https://open.spotify.com/intl-fr/artist/7jIZ4xeHqq7ZJzhsHlorTe?si=CqGoHDRZT1y4K7iiagbQOQ',
    'https://music.apple.com/us/artist/bermuda/1502456783',
    'https://bermudamusique.bandcamp.com/track/je-ne-mexcuserai-pas-remix',
    "Bermuda nous balance ses chansons qui oscille entre le funk, le rap et le pop avec énergie audace et tout ça sans compromis. Ambassadrice de l’inclusion, elle clame haut et fort qu’elle est bien dans sa peau et veut entraîner tout le monde dans son sillage. Je ne m’excuserai pas est un album rassembleur, audacieux et centré sur ses talents d’interprète explosive, un album mature et sans compromis. Frondeuse, fonceuse et un peu souvent irrévérencieuse, Bermuda vous invite dans son univers qui groove en français.",
    'KG7-glVM7ec'
  ),
  new CabaretArtist(
    'Fovelle',
    'fovelle',
    fevrier17,
    'https://fovelle.com/',
    'https://www.facebook.com/profile.php?id=100078014600869',
    'https://www.instagram.com/fovelle.mu/',
    'https://open.spotify.com/intl-fr/artist/6LoMfV6BitdhcaKkme41PS?si=qqUzohhiQoGggPdjPa0JzA',
    'https://music.apple.com/us/artist/fovelle/1603344444',
    'https://fovelle.bandcamp.com/',
    "Fovelle est un producteur de Nouveau-Disco/Electro-Pop québécois, un son qui lui permet de collaborer à chaque chansons avec des interprètes qu’il admire et de remixer des chansons qu’il adore. Habitué depuis 7 ans à créer avec divers groupes de funk, il jouit maintenant de la liberté et des infinis possibilités que lui offre la Maison Slique, son studio de la basse-ville de Québec. Armé de son ordinateur et de ses synthétiseurs analogiques, il se plaît à allier le soul du disco aux techniques de production modernes. Un son rafraîchissant qui tombe à point alors que le monde entier a besoin de faire la fête.",
    'Q-TLMvqN4k8'
  ),
  new CabaretArtist(
    'Olivier Lessard',
    'olivierlessard',
    mars9,
    'https://www.olivierlessard.ca/',
    'https://www.facebook.com/olilessardband',
    'https://www.instagram.com/olivier.lessard/',
    'https://open.spotify.com/intl-fr/artist/475de0iJKSQa7PebOU27Lk?si=v6HbiCluREqwnKY1W8713w',
    'https://music.apple.com/us/artist/olivier-lessard/1373921902',
    'https://olivierlessard.bandcamp.com/album/on-s-tait-arr-t-s-pisser-sur-la-route-cumberland-le-vent-soufflait-doucement',
    "Originaire de Saint-Georges, l'auteur-compositeur-interprète Olivier Lessard vit et travaille à Montréal. Il a fait paraître en 2018  « En attendant les normales de saison », un premier EP country-folk, après quoi il a participé à différentes vitrines, notamment aux Francouvertes en 2019. « On s'était arrêtés pisser sur la route Cumberland, le vent soufflait doucement » est son premier album; « Carnet de tournage » son premier livre.",
    'kiPXVswzap0'
  ),
  new CabaretArtist(
    'Madame Autruche',
    'madameautruche',
    mars9,
    'https://lecanalauditif.ca/artistes/madame-autruche/',
    'https://www.facebook.com/madameautruche',
    'https://www.instagram.com/madame_autruche_/',
    'https://open.spotify.com/intl-fr/artist/1DEhqITg6zAJz7TBWrs2PO?si=0kc4p02dR7eW6veEu4Ppsg',
    'https://music.apple.com/us/artist/madame-autruche/1466837572',
    'https://madameautruche.bandcamp.com/',
    "Madame Autruche est le projet musical de Mélisande Archambault. Elle a été instrumentiste pigiste notamment pour Les Royal Pickles, Canailles et Groenland pour ne nommer que ceux-ci. Grâce à ces collaborations, elle a pu se retrouver sur les scènes du Festival international de Jazz de Montréal, les Francos et le Coup de cœur francophone. Ses sonorités se trouvent à quelque part entre le folk, le rock et la pop des années 1970. Son premier album, Les pentes glissantes, voit le jour en juin 2019. On y retrouve des textes crus et imagés aux thématiques de désir, de fuite de la réalité et de grasse matinée entre autres.",
    'o2Gr_la0QT0'
  ),
  new CabaretArtist(
    'Cure-Pipe',
    'curepipe',
    mars9,
    'https://www.soluterecords.com/cure-pipe',
    'https://www.facebook.com/CURE.PIPEMUSIQUE',
    'https://www.instagram.com/cure_pipe/',
    'https://open.spotify.com/intl-fr/artist/2Bx1pKUBJRXL6myG0Q2hxc?si=TcAUJmMmS5i68WQyO2faZw',
    'https://music.apple.com/us/artist/cure-pipe/1551773069',
    'https://curepipe.bandcamp.com/',
    "Cure-Pipe, c’est avant tout le projet de l’auteur-compositeur-interprète originaire de Jonquière-Nord Thomas Dakin Perron, qui, depuis 2017, travaille activement à trouver un son qui lui est propre, tout en s’entourant de collaborateurs de talent pour créer une expérience musicale totalement éclatée et prenante. Ainsi, rock psychédélique et garage pop se mêlent aisément au rock classique et à l’hyperpop pour créer une recette surprenante, un peu crasseuse, mais surtout, totalement vraie. Thomas ne fait pas dans la demi-mesure. Avec Cure-Pipe, il se livre entièrement, sans compromis. Il y a de ces artistes qui nous obligent à s’arrêter et à prendre le temps d’écouter. Thomas est de ceux-là. Avec l’album Le Pire, paru en 2022 avec le soutien de Soluté Records, Cure-Pipe prend un virage pop tout en restant fidèle à ses racines garage. L'année 2023 marque un tournant important dans la carrière de Thomas, puisque l'album J'avais a rapidement attiré l'attention des mélomanes et des professionnels de l'industrie. Ainsi, Cure-Pipe se positionne maintenant comme un artiste important de la scène musicale québécoise.",
    'kk45G5fcBz8'
  ),
  new CabaretArtist(
    'Coco Rose',
    'cocorose',
    mars9,
    'https://cocorosemusic.com/',
    'https://www.facebook.com/CocoRosetheband',
    'https://www.instagram.com/coco__rose___/',
    'https://open.spotify.com/intl-fr/artist/7xCW1CZFidLxN51xIPitsl?si=LbhGNDd7RQGh57k1xjAMNg',
    'https://music.apple.com/us/artist/coco-rose/1618484444',
    'https://cocorose.bandcamp.com/album/love-faster',
    "Coco Rose est un groupe de Funk et de Soul basé à Montréal, actif depuis l'hiver 2019. Composé de 8 musiciens partageant le même objectif de jouer une musique groovy et festive, le groupe puise ses influences principalement dans des groupes du genre funk contemporain tels que Lawrence, Lettuce et Sammy Rae & The Friends. Composé en grande partie de musiciens ayant fréquenté une école de jazz, on peut entendre des influences de ce style dans leurs chansons, ainsi que de nombreux autres genres tels que le dixieland ou le R&B. Actuellement, le répertoire de Coco Rose est entièrement composé de chansons originales, écrites dans le but de créer une musique groovy et festive, avec des thèmes tels que l'inclusion, l'amitié et l'amour au premier plan.",
    'vnNydCM9cHY'
  )
]

function getCabaretCandidatBySlug (slug) {
  for (let i = 0; i < allCabaretCandidats.length; i++) {
    if (allCabaretCandidats[i].slug === slug) {
      return allCabaretCandidats[i]
    }
  }

  return undefined
}

const cabaretTicketUrl = "https://lepointdevente.com/billets/cabaretfestif2024?lang=fr"

module.exports = {
  getCabaretCandidatBySlug,
  allCabaretCandidats,
  cabaretMeta,
  cabaretTicketUrl
}
